<template>
  <div
    class="
      mt-3
      bg-white
      shadow
      sm:rounded-lg
      mx-4
      flex
      justify-between
      items-center
    "
  >
    <div class="w-full">
      <div
        @click="expandClicked"
        class="
          px-3
          py-4
          flex
          justify-between
          items-center
          cursor-pointer
          hover:bg-gray-50
          sm:rounded-lg
        "
      >
        <div
          class="
            text-base
            leading-6
            font-medium
            text-gray-800
            uppercase
            w-full
            select-none
            flex
            items-center
          "
        >
          <span
            class="relative inline-block mr-2"
            :class="{ hidden: !loading }"
          >
            <ClockIcon
              class="animate-spin h-4 w-4 text-gray-700"
              aria-hidden="true"
            />
          </span>
          <span class="relative inline-block mr-2">
            <PhoneIncomingIcon
              v-if="call.direction === 'in'"
              class="h-4 w-4 text-gray-700"
              aria-hidden="true"
            />
            <PhoneOutgoingIcon
              v-if="call.direction === 'out'"
              class="h-4 w-4 text-gray-700"
              aria-hidden="true"
            />
          </span>
          <span>{{ call.number }}</span>
          <span class="text-gray-600 text-sm ml-2"
            >(CID:{{ call.caller_id_num }}) {{ call.caller_id_name }}</span
          >
        </div>
        <button
          v-if="call.has_recordings"
          :title="'this call has ' + call.recordings.length + ' recording'"
          type="button"
          class="
            inline-flex
            mr-3
            items-center
            p-1
            border border-transparent
            rounded-full
            shadow-sm
            text-red-500
            hover:bg-indigo-200
            focus:outline-none
          "
        >
          <MicrophoneIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          v-if="call.answer_at"
          title="this call was answered"
          type="button"
          class="
            inline-flex
            mr-3
            items-center
            p-1
            border border-transparent
            rounded-full
            shadow-sm
            text-green-500
            hover:bg-indigo-200
            focus:outline-none
          "
        >
          <PhoneIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          @click.stop="openNotesDialog"
          :title="call.notes ?? 'none'"
          type="button"
          class="
            inline-flex
            mr-3
            items-center
            p-1
            border border-transparent
            rounded-full
            shadow-sm
            text-indigo-700
            bg-indigo-100
            hover:bg-indigo-200
            focus:outline-none
          "
        >
          <AnnotationIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <span class="relative inline-block">
          <ChevronUpIcon
            v-if="expanded"
            class="h-5 w-5 text-gray-700"
            aria-hidden="true"
          />
          <ChevronDownIcon
            v-if="!expanded"
            class="h-5 w-5 text-gray-700"
            aria-hidden="true"
          />
        </span>
      </div>

      <dl class="sm:divide-y" :class="{ hidden: !expanded }">
        <div></div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Notes</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ call.notes }}
          </dd>
        </div>

        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Recordings</dt>
          <dd
            v-if="call.recordings.length > 0"
            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          >
            <ul
              class="border border-gray-200 rounded-md divide-y divide-gray-200"
            >
              <li
                v-for="rec in call.recordings"
                :key="rec.download"
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <div class="w-0 flex-1 flex items-center">
                  <audio controls class="h-10 w-full">
                    <source :src="rec.link" type="audio/wav" />
                  </audio>
                </div>
                <!-- <div class="ml-4 flex-shrink-0">
                  <a
                    :href="rec.download_link"
                    :download="rec.recording_name"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Download
                  </a>
                </div> -->
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  AnnotationIcon,
  ClockIcon,
  PhoneOutgoingIcon,
  PhoneIncomingIcon,
  PhoneIcon,
  MicrophoneIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    AnnotationIcon,
    ClockIcon,
    PhoneOutgoingIcon,
    PhoneIncomingIcon,
    PhoneIcon,
    MicrophoneIcon,
  },
  props: {
    call: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      expanded: false,
      loading: false,
    };
  },
  methods: {
    async expandClicked() {
      this.expanded = !this.expanded;
    },
    openNotesDialog() {
      this.setCall({ call: this.call });
    },
    ...mapActions("callNotes", ["setCall"]),
  },
};
</script>
