<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="mx-8">
    <div v-if="error" class="mb-4">
      <ErrorAlert :text="error" />
    </div>
    <div
      class="
        lg:flex lg:items-center lg:justify-between
        pb-5
        border-b border-gray-300
      "
    >
      <div class="flex-1 min-w-0">
        <div class="flex items-center justify-between">
          <h2
            v-if="!loading"
            class="
              text-2xl
              font-bold
              leading-7
              text-gray-900
              sm:text-3xl sm:truncate
            "
          >
            {{ session.name.replace("ses", "session") }}
          </h2>
          <div></div>
          <span v-if="loading" class="relative inline-block">
            <ClockIcon
              class="animate-spin h-5 w-5 text-gray-700"
              aria-hidden="true"
            />
          </span>
        </div>
        <div
          v-if="!loading"
          class="
            mt-1
            flex flex-col
            sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6
          "
        >
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Starts {{ created }}
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Ends {{ expire }}
          </div>

          <div class="mt-2 flex items-center text-sm text-gray-500">
            <PhoneOutgoingIcon
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {{ session.calls.length }} Calls
          </div>

          <div class="mt-2 flex items-center text-sm text-gray-500">
            <ArrowCircleDownIcon
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <a
              href="javascript:void(0)"
              @click="downloadCalls"
              class="font-medium hover:text-gray-700"
              v-if="session.download_zip == null"
            >
              {{
                downloading || session.archiving
                  ? "Generating Download link.."
                  : "Generate Download link"
              }}
            </a>
            <a
              :href="session.download_zip"
              target="_blank"
              class="font-medium hover:text-gray-700"
              v-if="session.download_zip"
            >
              {{ "Download All Recordings" }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="mt-6">
      <SessionCall v-for="call in session.calls" :key="call.id" :call="call" />
    </div>
  </div>

  <CallNotesDialog />
</template>

<script>
import {
  CalendarIcon,
  PhoneOutgoingIcon,
  ClockIcon,
  ArrowCircleDownIcon,
} from "@heroicons/vue/solid";
import SessionCall from "@/modules/recordings/views/session_call.vue";
import recordingApi from "@/network/refactored/recordings.js";
import CallNotesDialog from "@/modules/call_notes/call_notes_dialog.vue";
import ErrorAlert from "@/components/ErrorAlert.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CalendarIcon,
    PhoneOutgoingIcon,
    ClockIcon,
    SessionCall,
    CallNotesDialog,
    ArrowCircleDownIcon,
    ErrorAlert,
  },

  data() {
    return {
      loading: false,
      downloading: false,
      error: false,
    };
  },

  async created() {
    this.loading = true;
    await this.loadSession();
    this.loading = false;
  },

  methods: {
    async downloadCalls() {
      if (this.downloading) return;
      this.downloading = true;
      try {
        const { data } = await recordingApi.downloadSessionCalls(
          this.session.id
        );

        const session = this.session;

        session.arching = data.arching;
        session.download_zip = data.download_zip;

        this.setSession(session);
      } catch (error) {
        this.error = error?.response?.data?.message ?? "Something went wrong";
      } finally {
        this.downloading = false;
      }
    },
    async loadSession() {
      const { data } = await recordingApi.loadSession(this.$route.params.id);
      this.setSession(data.data);
    },
    ...mapActions("RecordingsStore", ["setSession"]),
  },

  computed: {
    created() {
      const date = new Date(this.session.created_at * 1000);
      return date.toISOString().split("T")[0];
    },
    expire() {
      const date = new Date(this.session.expire_at * 1000);
      return date.toISOString().split("T")[0];
    },
    ...mapGetters("RecordingsStore", ["session"]),
  },
};
</script>
